<template>
  <v-chart class="chart" :option="option" />
  <div class="legend"><b>F1-score</b>. The Y-axis shows the number of predicted groups reaching the value given on the X-axis.
  </div>
</template>

<script>
import { use } from "echarts/core";
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import 'echarts/lib/component/markPoint'

use([
  GridComponent, LineChart, CanvasRenderer, UniversalTransition
]);

export default defineComponent({
  name: "F1",
  props: ['dataset'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  created() {
    fetch(`data/${this.dataset}.f1.json`)
      .then(res => {
        const json_data=res.json();
        return json_data
        })
      .then(json_data => this.create_plot(json_data))
  },
  data () {
    return {
      option: {
        },
      json_data: {},
      plot_data: [],
      pin_coord: [],
      legends: [],
      selected: {'orthofinder_mmseqs': false}
    }
  },
  methods: {
    create_plot (json_data) {
        // keep the loaded data
        this.json_data=json_data
        // set the filtered data
        this.filtered_data=this.json_data
        this.plot_data = []

        var data_as_dict = {}
        for(var i in Object.keys(json_data.toolname)) {
            data_as_dict[json_data.toolname[i]] = []
        }


        for(var i in Object.keys(json_data.toolname)) {
          if (json_data.dataset[i] == this.dataset){
                data_as_dict[json_data.toolname[i]].push([json_data.percent_included[i], json_data.value[i]])
            }
        }
        for(var tool in data_as_dict){
          this.legends.push(tool)
          this.plot_data.push(
            {
              type: 'line',
              showSymbol: true,
              symbolSize: 1,
              clip: false,
              data: data_as_dict[tool],
              name: tool,
              lineStyle: {
                  width: 0.5,
              },
            }
          )
        }

        this.option = ref({
            legend: {
              data: this.legends,
              top: 'top',
              selected: this.selected
            },
            yAxis: {
              type: 'value',
              name: 'Counts',
              nameLocation: 'middle',
              nameGap: 15,
              max: 80,
              axisLabel: {
                fontSize: 10
              },
              nameTextStyle: {
                fontSize: 10
              }
            },
            xAxis: {
              type: 'value',
              name: 'Value',
              nameLocation: 'middle',
              nameGap: 20,
              max: 100,
              axisLabel: {
                fontSize: 10
              },
              nameTextStyle: {
                fontSize: 10
              }
            },
            grid: {
                right: '5%',
                bottom: '3%',
                top: '35%',
                left: '5%',
                containLabel: true
            },
            series: this.plot_data
        });
      }
  }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-top-width: 1px;
}
</style>
