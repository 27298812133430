<template>
  <div class="about">
    <h1>Contact</h1>
    <p>
    You have a tool that would benefit others ?
    Fill the form below to have it benchmarked here
    </p>

    <form name="contact" action="/" method="POST" netlify style="text-align: left; max-width: 500px; margin: 0 auto">
      <input type="hidden" name="form-name" value="contact" />

      <div>email:</div>
      <input type="text" name="email" />

      <div>name:</div>
      <input type="text" name="name" />

      <div>tool repository:</div>
      <input type="text" name="repository" />

      <div>have you run it successfully with LEMOrtho standalone ?:</div>
      <input type="radio" name="run" value="yes"/> Yes
      <input type="radio" name="run" value="no" checked/> No

      <div>comments:</div>
      <textarea name="comments"></textarea>

      <div>
        <button>Submit</button>
      </div>  
      <br /><br />
    </form>

  </div>
  <Footer/>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'Submission',
  components: {
    Footer
  }
}
</script>

<style scoped>
.about {
  position:relative;
  top:10em;
}
textarea {
  width:30em;
  height:10em;
}
</style>
