<template>
  <div class="parent">
    <div class="legend"><b>Select up to three tools to show their details and compare them</b> at the <i>{{rank}}</i> level.</div>
    <ul>
      <li v-for="tool in tool_names">
        <label>
          <input
            type="checkbox"
            v-model="selected_tools"
            :value="tool"
            :disabled="
              selected_tools.length > 2 && selected_tools.indexOf(tool) === -1
            "
            
          />
          {{ tool }}
        </label>
      </li>
    </ul>
    <button @click="showComparison = true">View</button>
    <div class="overlay" v-if="showComparison">
      <div class="topbar">
        <span class="close_button" @click="close_overlay">&#10006;</span>
      </div>
      <Comparison
        :instance="instance"
        v-bind:rank="rank"
        :tools="selected_tools"
      />
    </div>
  </div>
</template>

<script>
import Comparison from "@/components/widgets/Comparison.vue";
export default {
  name: "Selector",
  props: ["instance", "rank"],
  components: { Comparison },
  data() {
    return {
      tool_names: [],
      selected_tools: [],
      showComparison: false,
    };
  },
  mounted() {
    fetch(`data/${this.instance}.tools.json`)
      .then((res) => res.json())
      .then((data) => {
        this.tool_names = data;
      });
  },
  methods: {
    close_overlay() {
      this.showComparison = false;
    },
  },
};
</script>

<style scoped>
.parent {
  position: relative;
}
li {
  max-width: 420px;
  margin: 0 auto;
  background: white;
  text-align: left;
  padding: 0.1em;
  border-radius: 5px;
  font-weight: 100;
}
label {
  color: black;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}
p {
  color: black;
}
.overlay {
  position:fixed;
  left:0;
  top:19em;
  padding:0;
  border:0;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 3000;
  overflow:scroll;
}
.close_button {
  background: white;
  color: #000;
  padding: 0.2em;
  margin: 0.3em;
  border-radius: 1.5em;
  width: 1.5em;
  display: inline-block;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 1001;
}
.topbar {
  width: 100%;
  height: 2.4em;
  background-color: #0288d1;
}
li label {
  font-weight: 100;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-bottom-width: 1px;
}
</style>
