<template>
  <div>
  <h2>
      <span class="highlight">{{ niceTitle }}</span>
  </h2>
  </div>
  <div class="content">
  <div v-for="(value, dataset) in structure[instance]">
    <div class="wide_block">

      <div class="instance_summary_description">
        <div
          v-for="value2 in structure[instance][dataset]"
          class="floating_block"
          v-bind:class="[value2.split(':')[1] == 'full' ? 'full_width' : '']"
        >
          <Precision
            v-if="value2.split(':')[0] == 'precision'"
            :dataset="dataset"
          />
          <Recall
            v-else-if="value2.split(':')[0] == 'recall'"
            :dataset="dataset"
          />
          <Heatmap
            v-else-if="value2.split(':')[0] == 'heatmap'"
            :dataset="dataset"
          />
          <Fp
            v-else-if="value2.split(':')[0] == 'fp'"
            :instance="instance"
            :dataset="dataset"
          />
          <f1
            v-else-if="value2.split(':')[0] == 'f1'"
            :dataset="dataset"
          />
          <l2
            v-else-if="value2.split(':')[0] == 'l2'"
            :instance="instance"
            :dataset="dataset"

          />
          <Memory
            v-else-if="value2.split(':')[0] == 'memory'"
            :dataset="dataset"
          />
          <Api
            v-else-if="value2.split(':')[0] == 'api'"
            :dataset="dataset"
          />
          <Runtime
            v-else-if="value2.split(':')[0] == 'runtime'"
            :dataset="dataset"
          />
          <RankingTable
            v-else-if="value2.split(':')[0] == 'ranking'"
            :instance="instance"

          />
          <Selector
            v-else-if="value2.split(':')[0] == 'selector'"
            :instance="instance"

          />
          <Textbox
            v-else-if="value2.split(':')[0] == 'textbox'"
            :instance="instance"
            :dataset="dataset"
          />
          <Description
            v-else-if="value2.split(':')[0] == 'description'"
            :instance="instance"
            :dataset="dataset"
          />
          <IDCard
            v-else-if="value2.split(':')[0] == 'idcard'"
            :instance="instance"
            :dataset="dataset"
          />
          <Stats
            v-else-if="value2.split(':')[0] == 'stats'"
            :dataset="dataset"
          />
          <Classifed
            v-else-if="value2.split(':')[0] == 'classified'"
            :instance="instance"
            :dataset="dataset"
          />
          <Auprc
            v-else-if="value2.split(':')[0] == 'auprc'"
            :instance="instance"
            :dataset="dataset"

          />
          <FilteringThreshold
            v-else-if="value2.split(':')[0] == 'filtering_threshold'"
            :instance="instance"
            :dataset="dataset"

          />
          <Composition
            class="double"
            v-else-if="value2.split(':')[0] == 'composition'"
            :instance="instance"
            :dataset="dataset"

          />
        </div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
  </div>
  <Footer/>
</template>

<script>
// @ is an alias to /src
import Comparison from "@/components/widgets/Comparison.vue";
import RankingTable from "@/components/widgets/RankingTable.vue";
import ComparisonTable from "@/components/widgets/ComparisonTable.vue";
import Precision from "@/components/widgets/Precision.vue";
import Recall from "@/components/widgets/Recall.vue";
import Heatmap from "@/components/widgets/Heatmap.vue";
import Fp from "@/components/widgets/Fp.vue";
import L2 from "@/components/widgets/L2.vue";
import F1 from "@/components/widgets/F1.vue";
import Memory from "@/components/widgets/Memory.vue";
import Api from "@/components/widgets/Api.vue";
import Runtime from "@/components/widgets/Runtime.vue";
import Ranking from "@/components/widgets/Ranking.vue";
import Selector from "@/components/widgets/Selector.vue";
import Textbox from "@/components/widgets/Textbox.vue";
import Description from "@/components/widgets/Description.vue";
import IDCard from "@/components/widgets/IDCard.vue";
import Stats from "@/components/widgets/Stats.vue";
import Classifed from "@/components/widgets/Classified.vue";
import FilteringThreshold from "@/components/widgets/FilteringThreshold.vue";
import Auprc from "@/components/widgets/Auprc.vue";
import Composition from "@/components/widgets/Composition.vue";
import contentStructure from "@/../public/data/structure.json";
import Footer from '@/components/Footer.vue';
import niceTitles from "@/../public/data/nice_titles.json";

export default {
  name: "InstanceDetails",
  props: {
    instance: String,
    niceTitles: Object
  },
  components: {
    Comparison,
    ComparisonTable,
    RankingTable,
    Precision,
    Recall,
    Heatmap,
    Fp,
    L2,
    F1,
    Api,
    Memory,
    Runtime,
    Ranking,
    Selector,
    Textbox,
    Description,
    IDCard,
    Classifed,
    FilteringThreshold,
    Auprc,
    Composition,
    Stats,
    Footer
  },
  data() {
    return {
      structure: contentStructure,
      isCollapsed: {},
      niceTitle: niceTitles[this.instance] ? niceTitles[this.instance] : this.instance
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  display: block;
  font-size: 1.3em;
  padding: 0.3em;
  margin-bottom: 0;
  color: black;
}
h3 {
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 0.3em 0.3em 0em 0.3em;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.wide_block {
  clear: both;
  padding: 0;
  display: block;
  border: 0;
  border-radius: 0.7em;
}
.floating_block {
  height: 30em;
  overflow-y: scroll;
  width: 17em;
  margin: 1em;
  float: left;
  background-color: white;
}

.full_width {
  width: 100%;
}

.double {
  width: 34em;
}
.instance_summary_description {
  margin-top: 1em;
}

.highlight {
  font-weight: 900;
  font-size: 1.1em;
  display: block;
  float:left;
}

.dataset_evaluation {
  font-weight: 900;
  font-variant: small-caps;
  font-size: 1.1em;
  background-color: slategrey;
  color: white;
  border: 0.1em solid white;
  border-radius: 1em;
  padding: 0 1em;
  margin-left: 2em;
}

.dataset_calibration {
  font-weight: 900;
  font-variant: small-caps;
  font-size: 1.1em;
  background-color: #000;
  color: white;
  border: 0.1em solid white;
  border-radius: 1em;
  padding: 0 1em;
  margin-left: 2em;
}

button.rank_selector {
  float: left;
  margin: 0 0 1em 0;
  padding: 0.2em;
  border: 0;
  background: none;
  cursor: pointer;
  color: #d84315;
  border-radius: 0.3em;
}

button.active {
  color: white;
  background: #d84315;
}

.taxonomic_rank_label {
  font-size: 0.9em;
  float: left;
  margin: 0 1em 0 0;
  padding: 0.1em;
}

.toggle_button {
  background: white;
  color: black;
  padding: 0.3em;
  margin: 0;
  border: 0;
  border-radius: 1.5em;
  width: 1.5em;
  display: inline-block;
  position: absolute;
  right: 2em;
  cursor: pointer;
}

.collapsed {
  display: none;
}

.expanded {
  display: inline-block;
}

.fixed {
  position:fixed;
  top:3em;
  background-color:white;
  border:1px solid lightgrey;
  border-left-width:0;
  border-top-width:0;
  border-right-width:0;
  padding-top:5em;
  z-index:1999;
}

.content {
}

.spacer {
  clear:both;
  height:2.2em;
}

button.share {
  margin:0 2em;
  background-color: #d84315;
  color:white;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 3px;
  cursor: pointer;
}

button.share:hover {
  border: 2px solid #d84315;
}

.tuto_collapse {
  color:white;
  position: absolute;
  right:8em;
  font-size: 0.6em;
  text-align: left;
}

.spacer_menu {
  height:10em;
}

</style>
