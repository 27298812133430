<template>
<div class="about">
<div class="spacer"></div>
LEMOrtho is an online resource and a pipeline dedicated to continuous benchmarking of newly published methods for orthology delineation.
<br/><br/>LEMOrtho provides a standalone pipeline to conduct personal benchmarking and to help adapting pipelines for the LEMOrtho online benchmarking. See the documentation section and the submission form above.
<br/><br/>
Our lab: <a target="_blank" href="https://www.ezlab.org/">https://www.ezlab.org/</a>
<br/><br/>
<div class="spacer"></div>
</div>
<Footer/>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'About',
  components: {
    Footer
  }
}
</script>

<style scoped>
img {
  margin: 0 1em 0 0;
  padding: 0;
  width: 1.5em;
}
.twitter {
    display: flex;
    align-items: center;
}
.twitter a:visited {
    color: #1DA1F2;
    font-weight: 900;
}
.twitter {
    color: #1DA1F2;
    font-weight: 900;
}
.about {
    font-family: "Arial", sans-serif;
    border: 1px solid #EEE;
    display: block;
    padding: 1em;
    text-align: left;
    position:relative;
    top:11em;
}

.spacer {
  clear:both;
  height:2.2em;
}


</style>
