<template>
  <v-chart class="chart" :option="option" />
  <div class="legend"><b>Agreement of the mapped proteins with the public database of the method.</b> When the protein exists in the online db, the OG assigned by mapping is compared to the OG in the db.</div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { DataZoomComponent } from 'echarts/components';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([
  CanvasRenderer,
  DataZoomComponent,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

export default defineComponent({
  name: "Api",
  legend: {},
  props: ['dataset'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  created() {
    fetch(`data/${this.dataset}.api.json`)
      .then(res => {
        const json_data=res.json();
        return json_data
        })
      .then(json_data => this.create_plot(json_data))
  },
  data () {
    return {
      option: {
        },
      json_data: {},
      plot_data: [],
      labels: []
    }
  },
  methods: {
    create_plot (json_data) {
        // keep the loaded data
        this.json_data=json_data
        // set the filtered data
        this.filtered_data=this.json_data
        this.plot_data = []
        this.tools = []
        this.correct = []
        this.incorrect = []
        for(var i in Object.keys(json_data.toolname)) {
          if (json_data.dataset[i] == this.dataset){
            this.correct.push(json_data.value[i].split("_")[0])
            this.incorrect.push(json_data.value[i].split("_")[1])
            this.tools.push(json_data.toolname[i])
          }
        }
        this.plot_data.push({name:'Agree', color:'#0000a9', data:this.correct, type: 'bar', showBackground: true, backgroundStyle: {color: 'rgba(180, 180, 180, 0.2)'}, stack: 'tools'})
        this.plot_data.push({name:'Disagree', color:'#a90000', data:this.incorrect, type: 'bar', showBackground: true, backgroundStyle: {color: 'rgba(180, 180, 180, 0.2)'}, stack: 'tools'})
        this.option = ref({
            legend: {
              top: 'top',
            },
            yAxis: {
              type: 'category',
              data: this.tools,
              axisLabel: {
                  rotate: 30,
                  fontsize: 11
              },
            },
            xAxis: {
                type: 'value',
                max: 1885,
                interval:1000
            },
            dataZoom: [{
                    type: 'slider',
                    top: '8%',
                    height: 15
                }],
            grid: {
                right: '5%',
                bottom: '0%',
                top: '15%',
                left: '0%',
                containLabel: true
            },
            series: this.plot_data
        });
      }
  }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-top-width: 1px;
}
</style>
