<template>
    <div class="table">
    <h4>Detailed predictions&nbsp;<span class="howto">Use shift-click to sort on multiple columns.</span></h4>
    <ag-grid-vue style="width:2000px; height:100%"
               class="ag-theme-alpine"
               :columnDefs="columnDefs"
               :onGridReady="onGridReady"
               :rowData="rowData.value"
               rowSelection="multiple">
    </ag-grid-vue>
    </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import {reactive, onMounted, onGridReady} from "vue";
import TrueFalseRenderer from "@/components/widgets/TrueFalseRenderer.vue";
import PresenceRenderer from "@/components/widgets/PresenceRenderer.vue";

export default {
  name: 'Stats',
  components: {
    AgGridVue,
    TrueFalseRenderer: TrueFalseRenderer,
    PresenceRenderer: PresenceRenderer
  },
  setup(props) {
    let rowData = reactive([]);
    rowData.value=[];
    onMounted(() => {
        fetch(`data/${props.dataset}.json`)
          .then(result => result.json())
          .then(remoteRowData => {
            for (var idx in remoteRowData) {
              rowData.value.push(remoteRowData[idx]);
            }
        })
    });
    return {
        columnDefs: [
            { field: 'toolname', sortable: true, headerName: 'Tool name' },
            { field: 'B', sortable: true, headerName: 'Num. of OGs (RefOGs=70)', cellClass: "grid-cell-centered", width: 200},
            { field: 'F185', sortable: true, headerName: 'RefOGs with F1 ≥85%', cellClass: "grid-cell-centered", width: 200},
            { field: 'Precision85', sortable: true, headerName: 'RefOGs with Precision ≥85%', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Recall85', sortable: true, headerName: 'RefOGs with Recall ≥85%', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Nexact', sortable: true, headerName: 'Exact', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Nakin', sortable: true, headerName: 'Akin', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Nfused', sortable: true, headerName: 'Fused clusters', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Fused_events', sortable: true, headerName: 'Fused events', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Nsplit', sortable: true, headerName: 'Split clusters', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Split_events', sortable: true, headerName: 'Split events', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Ncomplex', sortable: true, headerName: 'Complex', cellClass: "grid-cell-centered", width: 200 },
            { field: 'Nmissed', sortable: true, headerName: 'Missed', cellClass: "grid-cell-centered", width: 200 }
        ],
        onGridReady: (params) => {
            params.columnApi.applyColumnState({ state: [{colId: 'F185', sort: 'desc', sortIndex: 0},
                                                 {colId: 'toolname', sort: 'asc', sortIndex: 1}
                                                ] });
        },
        rowData
    };
  },
  props: {
    dataset: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid-cell-centered {
    text-align: center;
    background-color:red;
}
.table {
  margin:0;
  padding:0;
  background-color: #FFF;
  text-align:left;
  border:0;
  height:22em;
  color:black;
  text-align:left;
}
.table th {
  background-color:#F8FBFD;
  color:black;
  padding:1em;
  border-radius: 1em;
}
.table td {
  background-color:#F8F8F8;
  padding:0;
  margin:0;
}
.table tr {
  padding:0;
  margin:0;
  height:0;
}
.paragraph {
  font-size:0.9em;
  margin-bottom:0.9em;
  text-align:left;
}
h4 {
  padding:0;
  margin:0;
}
.howto {
    color:grey;
    font-size:0.66em;
}
</style>
